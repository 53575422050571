import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { dateStringFormat } from "../common/DateFormatting";
import { showCurrencyRoundedDown } from "./DisplayPriceComponents";
import { useEffect, useState } from "react";
import ItemSnippet from "../common/ItemSnippet";
import BasicModal from "../common/BasicModal";
import { useEnv } from "../../context/env.context";
import { InsurancePlanDto, PricePeriod } from "../../api/rentMyApi";

interface Discount {
  id: number;
  name: string;
  amount: number;
}

interface Props {
  dailyPrice: string;
  daysBetween: number;
  totalRangePriceWithoutFee: string;
  serviceFeeAmount: string;
  discountTotal: number;
    total: string;
  discounts: string[]; // Add a new prop for discounts
  insuranceTotal: number;
  showInsuranceTotal?: boolean;
  insurancePremiumTax: number;
  isInsured: boolean;
  insuranceDetails?: InsurancePlanDto;
  hasInsurancePlan?: boolean;
  insuranceRequired?: boolean;
  insuranceAccepted?: boolean;
  onInsuranceAcceptedChange?: (value: boolean) => void;
  showInsurance?: boolean;
  showIPT: boolean;
  insuranceApplied?: boolean;
  setInsuranceApplied?: (value: boolean) => void;
  confirmationPage?: boolean;
  itemDetailedPage: boolean;
}

const insuranceLogoStyle = {
  width: "100px",
};

export default function PriceBreakdown({
  dailyPrice,
  daysBetween,
  totalRangePriceWithoutFee,
  serviceFeeAmount,
    discountTotal,
  total,
  discounts, // Add the new prop
  insuranceTotal,
  showInsuranceTotal,
  insurancePremiumTax,
  hasInsurancePlan,
  insuranceDetails,
  insuranceRequired,
  insuranceAccepted,
  onInsuranceAcceptedChange,
  showInsurance,
  insuranceApplied,
  setInsuranceApplied,
  confirmationPage,
  itemDetailedPage,
}: Props) {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  const numberOfDaysForDiscountToBeSeenAsWeekly = 5;
  const numberOfDaysForDiscountToBeSeenAsMonthly = 30;
  const [modalInsurance, setModalInsurance] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [InsuranceCompanyTitle, setInsuranceCompanyTitle] = useState("");
  const [InsuranceCompanyImage, setInsuranceCompanyImage] = useState("");
  const [InsuranceName, setInsuranceName] = useState("");

  useEffect(() => {
    if (enableInsurance && insuranceDetails) {
      const name = insuranceDetails.insuranceCompany.name;
      const image = insuranceDetails.insuranceCompany.image;
      const src = image?.compressedPath
        ? image.compressedPath
        : "/assets/img/placeholder.jpg";
      const description = insuranceDetails.name;

      setInsuranceCompanyImage(src);
      setInsuranceCompanyTitle(name ?? "");
      setInsuranceName(description ?? "");
    }

    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [insuranceDetails]);

  // Calculate the total discount amount
  let totalDiscountAmount = 15 * discounts.length;

  // Ensure the total discount amount does not exceed 15% of the total
  const maxDiscount = parseFloat(total) * 0.15;
  if (totalDiscountAmount > maxDiscount) {
    totalDiscountAmount = maxDiscount;
  }

  // Calculate the new total after applying the discounts
  let newTotal = parseFloat(total);
  // console.log("IS INSURACE REQUIRED?", insuranceRequired)
  // if (insuranceRequired || insuranceApplied) {
  //   newTotal += insuranceTotal;
  // }
  if (newTotal >= 60) {
    newTotal -= totalDiscountAmount;
  }

  if (insuranceRequired && !insuranceAccepted) {
    newTotal += insuranceTotal;
  }

  return (
    <>
      <Card>
        <CardBody>
          <h2>{t("item_detailed_confirmation_price_breakdown")}</h2>
                  <div className="flexy item-detailed-price-breadown">

            <p id="date-range-price" className="flex-vertically-center">
              &#163;{dailyPrice}
              {t("item_detailed_per_day_alt")} x {daysBetween}{" "}
              {daysBetween > 1 ? t("days") : t("day")}
            </p>
            <p className="flex-right">&#163;{totalRangePriceWithoutFee}</p>
          </div>
          <div className="flexy item-detailed-price-breadown">
            <p id="date-range-price" className="flex-vertically-center">
              {t("rent_my_fee")}
            </p>
            <p className="flex-right">&#163;{serviceFeeAmount}</p>
                  </div>

          {enableInsurance &&
            showInsuranceTotal &&
            !showInsurance &&
            (isMobile ? (
              <>
                {insuranceTotal > 0 && insuranceRequired && (
                  <div className="flexy item-detailed-price-breadown justify-content-space-between margin-bottom-neg-30">
                    <p className="flex-vertically-center margin-top-10">
                      {InsuranceName}
                    </p>
                    <div
                      className="margin-top-10"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-right",
                      }}
                    >
                      <p className="flex-right">
                        &#163;{insuranceTotal.toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}
                {insurancePremiumTax > 0 && insuranceRequired && (
                  <p
                    className="margin-top-20 padding-top-10"
                    style={{ fontSize: "0.7em" }}
                  >
                    {"Includes Insurance Premium Tax of £" +
                      insurancePremiumTax.toFixed(2)}
                  </p>
                )}
              </>
            ) : (
              enableInsurance &&
              insuranceRequired && (
                <div>
                  <div className="flexy item-detailed-price-breadown justify-content-space-between">
                    <p className="flex-vertically-center">{InsuranceName}</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <p className="flex-right">
                        &#163;{insuranceTotal.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  {insurancePremiumTax > 0 && (
                    <p style={{ fontSize: "0.7em" }}>
                      {"Includes Insurance Premium Tax of £" +
                        insurancePremiumTax.toFixed(2)}
                    </p>
                  )}
                </div>
              )
            ))}

          {enableInsurance && showInsurance && (
            <>
              {isMobile &&
                hasInsurancePlan &&
                (!insuranceRequired ? (
                  <div>
                    <div className="flexy item-detailed-price-breadown justify-content-space-between margin-bottom-10">
                      <p className="flex-vertically-center margin-top-10 font-weight-400">
                        {InsuranceName}
                      </p>
                      <div
                        className="margin-top-10"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-right",
                        }}
                      >
                        <p className="flex-right">
                          &#163;{insuranceTotal.toFixed(2)}
                        </p>
                        <p style={{ fontSize: "0.7em" }}>
                          {"Includes Insurance Premium Tax of £" +
                            insurancePremiumTax.toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <ItemSnippet
                      confirmationPage={confirmationPage}
                      wantInCard={false}
                      text={{
                        title: "",
                        text: "",
                        secondaryText: ``,
                        bottomText:
                          "Add insurance to this item for protection in the case of accidental damage or loss?\nBy adding this insurance, you confirm that you have read and understood the policy documents provided above",
                      }}
                      toggleImageSrc={
                        insuranceDetails?.insuranceCompany.image.path
                      }
                      imageStyle={insuranceLogoStyle}
                      outerStyle={true}
                      // toggleImageSrc={InsuranceCompanyImage}
                      imageBelow={true}
                      toggleModal={() =>
                        setModalInsurance((prevState) => !prevState)
                      }
                      // look for changes here
                      // this is not mobile, optional insurance
                      universalToggleSetup={{
                        value: insuranceApplied!,
                        setValue: () => {
                          setInsuranceApplied &&
                            setInsuranceApplied(!insuranceApplied);
                          onInsuranceAcceptedChange &&
                            onInsuranceAcceptedChange(!insuranceAccepted);
                        },
                        disabled: false,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="flexy item-detailed-price-breadown">
                      <p className="flex-vertically-center font-weight-400">
                        {InsuranceName}
                      </p>
                      <div
                        className="margin-top-10 flex-right"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-right",
                        }}
                      >
                        <p className="flex-right">
                          &#163;{insuranceTotal.toFixed(2)}
                        </p>
                        <p className="flex-left" style={{ fontSize: "0.7em" }}>
                          {"Includes Insurance Premium Tax of £" +
                            insurancePremiumTax.toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <ItemSnippet
                      confirmationPage={confirmationPage}
                      outerStyle={true}
                      wantInCard={false}
                      text={{
                        title: "",
                        text: "",
                        secondaryText: ``,
                        bottomText:
                          "The owner of this item requests you add insurance to protect you both from costs and fees resulting from damage or loss. \nBy adding this insurance, you confirm that you have read and understood the policy documents provided above",
                      }}
                      toggleImageSrc={
                        insuranceDetails?.insuranceCompany.image ?
                            insuranceDetails?.insuranceCompany.image.path : ""
                      }
                      // toggleImageSrc={InsuranceCompanyImage}
                      imageStyle={insuranceLogoStyle}
                      imageBelow={true}
                      toggleModal={() =>
                        setModalInsurance((prevState) => !prevState)
                      }
                      universalToggleSetup={{
                        value: insuranceApplied!,
                        setValue: () => {
                          setInsuranceApplied &&
                            setInsuranceApplied(!insuranceApplied);
                          onInsuranceAcceptedChange &&
                            onInsuranceAcceptedChange(!insuranceAccepted);
                        },
                        disabled: false,
                      }}
                    />
                  </div>
                ))}

              {isMobile && !hasInsurancePlan && (
                <div>
                  <Row>
                    <Col className="flexy justify-content-space-between pt-3"></Col>
                  </Row>
                </div>
              )}

              {!isMobile &&
                hasInsurancePlan &&
                (!insuranceRequired ? (
                  <div>
                    <div className="flexy item-detailed-price-breadown justify-content-space-between margin-bottom-neg-30">
                      <p className="flex-vertically-center margin-top-10 font-weight-400">
                        {InsuranceName}
                      </p>
                      <div
                        className="margin-top-10"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-right",
                        }}
                      >
                        <p className="flex-right">
                          &#163;{insuranceTotal.toFixed(2)}
                        </p>
                        <p style={{ fontSize: "0.7em" }}>
                          {"Includes Insurance Premium Tax of £" +
                            insurancePremiumTax.toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <ItemSnippet
                      confirmationPage={confirmationPage}
                      wantInCard={false}
                      text={{
                        title: "",
                        text: "",
                        secondaryText: ``,
                        bottomText:
                          "Add insurance to this item for protection in the case of accidental damage or loss?\n" +
                          "By adding this insurance, you confirm that you have read and understood the policy documents provided above",
                      }}
                      toggleImageSrc={
                        insuranceDetails?.insuranceCompany.image ?
                        insuranceDetails?.insuranceCompany.image.path : ""
                      }
                      imageStyle={insuranceLogoStyle}
                      outerStyle={true}
                      // toggleImageSrc={InsuranceCompanyImage}
                      imageBelow={true}
                      toggleModal={() =>
                        setModalInsurance((prevState) => !prevState)
                      }
                      // look for changes here
                      // this is not mobile, optional insurance
                      universalToggleSetup={{
                        value: insuranceApplied!,
                        setValue: () => {
                          setInsuranceApplied &&
                            setInsuranceApplied(!insuranceApplied);
                          onInsuranceAcceptedChange &&
                            onInsuranceAcceptedChange(!insuranceAccepted);
                        },
                        disabled: false,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="flexy item-detailed-price-breadown">
                      <p className="flex-vertically-center font-weight-400">
                        {InsuranceName}
                      </p>
                      <div
                        className="margin-top-10 flex-right"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-right",
                        }}
                      >
                        <p className="flex-right">
                          &#163;{insuranceTotal.toFixed(2)}
                        </p>
                        <p className="flex-left" style={{ fontSize: "0.7em" }}>
                          {"Includes Insurance Premium Tax of £" +
                            insurancePremiumTax.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <ItemSnippet
                      confirmationPage={confirmationPage}
                      outerStyle={true}
                      wantInCard={false}
                      text={{
                        title: "",
                        text: "",
                        secondaryText: ``,
                        bottomText:
                          "The owner of this item requests you add insurance to protect you both from costs and fees resulting from damage or loss.\n" +
                          "By adding this insurance, you confirm that you have read and understood the policy documents provided above",
                      }}
                      toggleImageSrc={
                        insuranceDetails?.insuranceCompany.image.path
                      }
                      // toggleImageSrc={InsuranceCompanyImage}
                      imageStyle={insuranceLogoStyle}
                      imageBelow={true}
                      toggleModal={() =>
                        setModalInsurance((prevState) => !prevState)
                      }
                      universalToggleSetup={{
                        value: insuranceApplied!,
                        setValue: () => {
                          setInsuranceApplied &&
                            setInsuranceApplied(!insuranceApplied);
                          onInsuranceAcceptedChange &&
                            onInsuranceAcceptedChange(!insuranceAccepted);
                        },
                        disabled: false,
                      }}
                    />
                  </div>
                ))}

              {!isMobile && !hasInsurancePlan && (
                <div>
                  <Row></Row>
                </div>
              )}
              <BasicModal
                modalTitle={"Rental Insurance"}
                modalBody={
                  <div className="text-align-center">
                    {
                      "Our insurance is underwritten, ensuring you’re protected in case of any accidents or damage during your rental."
                    }
                  </div>
                }
                modalState={modalInsurance}
                toggleModal={() => setModalInsurance(false)}
                className="aviva-insurance-modal"
              />
            </>
          )}
          {/* Add a section to display the total discount amount */}
          {discountTotal > 0 && (
            <div
              className="flexy item-detailed-price-breadown text-red"
              style={{ borderBottom: "1px solid lightgrey" }}
            >
              <p className="flex-vertically-center mt-1 pb-2">
                {t("discount")}
              </p>
              <p className="flex-right">-&#163;{discountTotal.toFixed(2)}</p>
            </div>
          )}

          {/*<hr className="small-hr" />*/}
          <div className="flexy">
            <p className="flex-vertically-center font-weight-600">
              {t("item_detailed_total")}
            </p>
            <p className="flex-right font-weight-600">
              &#163;{newTotal.toFixed(2)}
            </p>
          </div>
          {!confirmationPage && insuranceTotal > 0 && !insuranceRequired && (
            <hr className="small-hr" />
          )}
          {!confirmationPage && insuranceTotal > 0 && !insuranceRequired && (
            <div className="flexy item-detailed-price-breadown justify-content-space-between margin-bottom-neg-30">
              <p className="flex-vertically-center margin-top-10 font-weight-500">
                {InsuranceName} (Optional)
              </p>
              <div
                className="margin-top-10"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-right",
                }}
              >
                <p className="flex-right">&#163;{insuranceTotal.toFixed(2)}</p>
              </div>
            </div>
          )}
          {!confirmationPage && insurancePremiumTax > 0 && !insuranceRequired && (
            <p
              className="margin-top-20 padding-top-10"
              style={{ fontSize: "0.7em" }}
            >
              {"Includes Insurance Premium Tax of £" +
                insurancePremiumTax.toFixed(2)}
            </p>
          )}
        </CardBody>
      </Card>
    </>
  );
}
