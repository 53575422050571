import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import {
  BookingClient,
  BookingDetailedDto,
  BookingStatus,
  FileClient,
  IConfig,
  ItemSimpleDto,
  QuotallClaimCreateDto,
  QuotallClaimsClient,
  QuotallLossTypeDto,
} from "../../api/rentMyApi";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import MakeClaimModal from "./MakeClaimModal";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, Container } from "reactstrap";
import { ImagePromise } from "../../models/interfaces/Image";

// Types for the state
interface ItemDetail {
  label: string;
  value: string;
}

interface ClaimDetail {
  label: string;
  value: string;
  fullWidth?: boolean;
  select?: boolean;
  disabled?: boolean;
  currency?: boolean;
}

const initialItemDetails: ItemDetail[] = [
  { label: "Item Value", value: "..." },
  { label: "Rental Start", value: "..." },
  { label: "Rental End", value: "..." },
];

const initialClaimDetails: ClaimDetail[] = [
  { label: "Claim Reason", value: "", select: true },
  {
    label: "Claim Date",
    value: moment(new Date()).format("DD-MM-YYYY"),
    disabled: true,
  },
  { label: "Claim Value", value: "", disabled: false, currency: true },
  { label: "Claim Description", value: "", fullWidth: true },
];

interface Ioption {
  label: string | undefined;
  value: number;
  id?: string | undefined;
  itemName?: string | undefined;
  item?: ItemSimpleDto | undefined;
  itemValue?: number;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

interface UploadRequestFile {
  file: string;
  name: string;
  rawFile: any;
}

export default function MakeAClaim() {
  const { t } = useTranslation();

  // State declarations

  // all translations
  const [bookings, setBookings] = useState<BookingDetailedDto[]>([]);
  // selected transaction
  const [selectedBooking, setSelectedBooking] = useState<
    BookingDetailedDto | undefined
  >(undefined);
  // itemValue
  const [itemValue, setItemValue] = useState<number | undefined>(undefined);
  // rental startDate
  const [startDate, setStartDate] = useState<string | undefined>("...");
  // Rental end
  const [endDate, setEndDate] = useState<string | undefined>("...");
  // claim reason
  const [claimReason, setClaimReason] = useState<string>("");
  // claim date
  const [claimDate, setClaimDate] = useState<Date | undefined>(new Date());
  // claim value
  const [claimValue, setClaimValue] = useState<string | undefined>(undefined);
  // claim description
  const [claimDescription, setClaimDescription] = useState<string | undefined>(
    undefined
  );
  // supporting images
  const [supportingImages, setSupportingImages] = useState<File[]>([]);
  // terms accepted
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isClientLoaded, setIsClientLoaded] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { bookingid: bookingId } = useParams<{ bookingid?: string }>();

  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const [reasonNote, setReasonNote] = useState<string>("");

  const [bookingClient, setBookingClient] = useState(
    new BookingClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [lossTypes, setLossTypes] = React.useState<QuotallLossTypeDto[]>([]);

  const [quotallClaimClient, setQuotallClaimClient] = useState(
    new QuotallClaimsClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    )
  );

  const [chosenClaimReason, setChosenClaimReason] = useState<
    QuotallLossTypeDto | undefined
  >(undefined);
  const [chosenLossCode, setChosenLossCode] = useState<string>("NONE");

  const [apiFileClient, setApiFileClient] = useState(
    new FileClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );

  const pageSize = 50;

  const [uploadedFiles, setUploadedFiles] = useState<UploadRequestFile[]>([]);

  const onFileSelect = (file: File) => {
    // Read the file as a Base64 string
    const reader = new FileReader();
    reader.onloadend = () => {
      // Ensure that reader.result is a string before setting the state
      if (typeof reader.result === "string") {
        setUploadedFiles([
          ...uploadedFiles,
          {
            file: reader.result,
            name: file.name,
            rawFile: file,
          } as UploadRequestFile,
        ]);
      } else {
        // Handle the case where reader.result is not a string
        console.error("File could not be read as a Base64 string.");
      }
    };
    reader.readAsDataURL(file);
  };

  // helper functions:

  const removeFile = (indexToRemove: number) => {
    setUploadedFiles(
      uploadedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleClaimReasonChange = (event: SelectChangeEvent<string | "">) => {
    const selectedOption = lossTypes.find(
      (option) => option.name === event.target.value
    );
    setChosenClaimReason(selectedOption);
    if (selectedOption && selectedOption.code) {
      setChosenLossCode(selectedOption.code);
      if (["LOSS", "STOL", "THEF"].includes(selectedOption.code)) {
        if (["STOL", "THEF"].includes(selectedOption.code)) {
          setReasonNote("Please include the crime reference number");
        } else {
          setReasonNote("");
        }
      } else {
        setReasonNote("");
      }
    } else {
      setChosenLossCode("NONE");
      setReasonNote("");
    }
  };

  const activeBookingsStatuses = [
    BookingStatus.Ongoing,
    BookingStatus.BookingAccepted,
    BookingStatus.HandoverAccepted,
  ];

  interface UploadCardProps {
    onFileSelect: (file: File) => void;
  }
  const UploadCard = ({ onFileSelect }: UploadCardProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const handleUploadClick = () => {
      hiddenFileInput.current?.click();
    };

    const handleFileChange = (event: any) => {
      const fileUploaded = event.target.files[0];
      onFileSelect(fileUploaded);
    };

    const handleDrop = (event: any) => {
      event.preventDefault();
      const fileUploaded = event.dataTransfer.files[0];
      onFileSelect(fileUploaded);
    };

    const preventDefault = (event: any) => {
      event.preventDefault();
    };

    return (
      <Card
        sx={{
          height: "120px",
          width: "120px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2.5px dashed #dadada", // Solid black dashed border
          borderRadius: "8px", // Rounded corners, adjust radius as needed
          cursor: "pointer", // Changes the cursor to indicate the card is clickable
          "&:hover": {
            borderColor: "#ea554a", // Changes border color on hover, if desired
          },
          boxSizing: "border-box", // Include the border in the width/height
        }}
        onClick={handleUploadClick}
        onDragOver={preventDefault}
        onDrop={handleDrop}
      >
        <CardActionArea
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "120px",
            width: "120px",
          }}
        >
          <AddIcon sx={{ fontSize: "3rem", color: "#ea554a" }} />
        </CardActionArea>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*, .pdf"
        />
      </Card>
    );
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove non-numeric characters except for the decimal point
    const value = event.target.value.replace(/[^0-9.]/g, "");
    setClaimValue(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Format the value on blur to maintain two decimal places
    const formattedValue = formatCurrencyValue(claimValue);
    setClaimValue(formattedValue);
  };

  const formatCurrencyValue = (value: string | undefined) => {
    // Format the value as a currency string
    if (value) {
      const numberValue = parseFloat(value);
      return numberValue.toFixed(2);
    }
    return "";
  };

  const handleClaimSubmit = async () => {
    setIsLoading(true);

    if (!selectedBooking) {
      toast.error("Please select a transaction");
      setIsLoading(false);
      return;
    }

    if (!chosenClaimReason?.id) {
      toast.error("Please select a claim reason");
      setIsLoading(false);
      return;
    }

    if (!claimDescription?.trim()) {
      toast.error("Please add a claim description");
      setIsLoading(false);
      return;
    }

    if (!claimValue?.trim() || parseFloat(claimValue) <= 0) {
      toast.error("Please add a claim value");
      setIsLoading(false);
      return;
    }

    const imagePromises: Promise<any>[] = [];
    const documentPromises: Promise<any>[] = [];

    for (const file of uploadedFiles) {
      const updated_image: File = new File([file.rawFile], file.name);

      if (
        file.name.endsWith(".pdf") ||
        file.name.endsWith(".docx") ||
        file.name.endsWith(".txt")
      ) {
        documentPromises.push(
          apiFileClient.upload4("." + file.name.split(".")[1], {
            fileName: updated_image.name,
            data: file.rawFile,
          })
        );
      } else {
        imagePromises.push(
          apiFileClient.upload("." + file.name.split(".")[1], {
            fileName: updated_image.name,
            data: file.rawFile,
          })
        );
      }
    }

    const uploadedDocumentsSettled = await Promise.allSettled(documentPromises);
    const uploadedImagesSettled = await Promise.allSettled(imagePromises);

    const uploadedDocuments = uploadedDocumentsSettled.map(
      (promise: ImagePromise) => promise.value
    );
    const uploadedImages = uploadedImagesSettled.map(
      (promise: ImagePromise) => promise.value
    );

    //
    // const totalImages = data.length
    // const fulfilled = data.filter((promise: ImagePromise) => promise.status === 'fulfilled')
    // let images = fulfilled.map((promise: ImagePromise) => promise.value)
    //
    // // remove any elements without an imageId
    // images = images.filter(image => image && (image.id !== undefined))
    //
    // if (totalImages === fulfilled.length) {
    //     toast.success(t('item_listing_upload_succes'))
    // } else {
    //     toast.warn(`${images.length} ${t('item_listing_out_of')} ${totalImages} ${t('item_listing_partial_upload')}`)
    // }

    // active!: boolean | undefined;
    // bookingId!: string;
    // description!: string | undefined;
    // lossDate!: Date;
    // lossTypeId!: string;
    // images!: string[] | undefined;
    // videos!: string[] | undefined;
    // documents!: string[] | undefined;
    // value!: number | undefined;

    const QuotallClaimData = {
      bookingId: selectedBooking!.id,
      description: claimDescription,
      lossDate: new Date(),
      lossTypeId: chosenClaimReason?.id ?? "",
      value: claimValue ? parseFloat(claimValue) : undefined,
      images: uploadedImages
        .filter((image) => image && image.id)
        .map((image) => image!.id),
      videos: [],
      documents: uploadedDocuments
        .filter((document) => document && document.id)
        .map((document) => document!.id),
      active: true,
    };
    // console.log("QuotallClaimData")
    // console.log(QuotallClaimData)

    quotallClaimClient
      .createPOST20(new QuotallClaimCreateDto(QuotallClaimData))
      .then((res) => {
        setIsLoading(false);
        toast.success(
          "Claim submitted successfully, you will receive an email when an update is available"
        );
        navigate("/account/my-items");
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Failed to create a claim");
      });
  };

  const handleBookingChange = (event: SelectChangeEvent<number | "">) => {
    const selectedOption = bookings[Number(event.target.value)];
    if (selectedOption) {
      setSelectedBooking(selectedOption);
      setItemValue(selectedOption.item?.itemValue);
      setStartDate(moment(selectedOption.startDate).format("DD-MM-YYYY"));
      setEndDate(moment(selectedOption.endDate).format("DD-MM-YYYY"));
      setClaimDate(new Date());
      setClaimValue(undefined);
      setClaimDescription("");
      setSupportingImages([]);
      setAgreeTerms(false);
      setChosenClaimReason(undefined);
      setChosenLossCode("NONE");
      setReasonNote("");
    }
    setSelectedBooking(selectedOption || "");
    // console.log(chosenTransaction)
  };

  // Use effects:

  useEffect(() => {
    const lastElement = scrollContainerRef.current?.lastElementChild;
    lastElement?.scrollIntoView({ behavior: "smooth", inline: "end" });
  }, [uploadedFiles.length]);

  useEffect(() => {
    quotallClaimClient.losstypes2().then((lossTypesPagination) => {
      setLossTypes(lossTypesPagination.data ?? []);
    });
  }, [quotallClaimClient]);

  useEffect(() => {
    if (bookingId && bookings.length > 0) {
      const defaultSelectedBooking = bookings.find(
        (booking) => booking.id === bookingId
      );
      if (defaultSelectedBooking) {
        setSelectedBooking(defaultSelectedBooking);
        setItemValue(defaultSelectedBooking.item!.itemValue!);
        setStartDate(
          moment(defaultSelectedBooking.startDate).format("DD-MM-YYYY")
        );
        setEndDate(moment(defaultSelectedBooking.endDate).format("DD-MM-YYYY"));
        setClaimDate(new Date());
        setClaimValue(undefined);
        setClaimDescription("");
        setSupportingImages([]);
        setAgreeTerms(false);
        setChosenClaimReason(undefined);
        setChosenLossCode("NONE");
        setReasonNote("");
      }
    }
  }, [bookingId, bookings]);

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      setApiFileClient(
        new FileClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT)
      );
      const quotallClaimClient = new QuotallClaimsClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      const client = new BookingClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      setQuotallClaimClient(quotallClaimClient);
      setBookingClient(client);
      setIsClientLoaded(true);
    }

    initClient();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isClientLoaded && bookingClient) {
      // bookingClient.detailed2(true, false, isSharerView, undefined, false, pageSize, 1)
      //     .then(res => {
      //         if (res) {
      //             if (res.data) {
      //                 const filteredData = res.data.filter(booking => activeBookingsStatuses.includes(booking.bookingStatus)).filter(booking => booking.insuranceBreakdown !== undefined)
      //                 setBookings(filteredData);
      //
      //                 const sevenDaysAgo = new Date();
      //                 sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      //
      //                 const newBookings = filteredData
      //                     .filter(transaction =>
      //                         transaction.item?.name !== undefined && // Make sure name is not undefined
      //                         // [BookingStatus.Completed,  BookingStatus.Ongoing].includes(transaction.bookingStatus) && // Make sure bookingStatus is either BookingAccepted or Ongoing
      //                         transaction.startDate && // Check if startDate is not null or undefined
      //                         transaction.endDate
      //                         // && // Check if endDate is not null or undefined
      //                         // (new Date(transaction.startDate) >= sevenDaysAgo || // Start date is within the last 7 days
      //                         //     new Date(transaction.endDate) >= sevenDaysAgo) // End date is within the last 7 days
      //                     )
      //                 setBookings(newBookings);
      //             }
      //         }
      //     })
      //     .catch((e) => {
      //         toast.error(t("account_booking_err_notifications"));
      //     });
      bookingClient
        .detailed(bookingId!, undefined, undefined)
        .then((booking) => {
          booking && setBookings([booking]);
        })
        .catch((e) => {
          toast.error("A booking with the provided ID was not found");
        });
    }
  }, [isClientLoaded, bookingClient]);

  return (
    <>
      <Container>
        <Paper
          sx={{
            padding: "2rem",
            pt: 1,
            margin: matchesSmall ? "0" : "2rem",
            mt: matchesSmall ? 1 : 4,
          }}
        >
          <Grid container item xs={12} md={12} sx={{ pt: "1rem" }}>
            <Grid item xs={6}>
              <Typography color={"black"} fontWeight={600} variant="h5">
                {"Submit Claim"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={6}>
              <Grid
                container
                spacing={2}
                sx={{ margin: "auto", width: "100%" }}
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    paddingLeft: "0 !important",
                    marginLeft: "0 !important",
                  }}
                >
                  <Typography
                    color="black"
                    fontWeight={600}
                    variant="h6"
                    gutterBottom
                    component="div"
                    style={{ marginTop: 16, paddingLeft: 0, marginLeft: 0 }}
                  >
                    Transaction
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    paddingLeft: matchesSmall
                      ? "0px !important"
                      : "6px !important",
                    marginLeft: "0 !important",
                    paddingTop: matchesSmall
                      ? "0px !important"
                      : "16px !important",
                    paddingBottom: matchesSmall ? "8px" : undefined,
                  }}
                >
                  <Select
                    size="small"
                    fullWidth
                    value={
                      selectedBooking === undefined
                        ? ""
                        : bookings.findIndex(
                            (booking) => booking.id === selectedBooking.id
                          )
                    }
                    onChange={handleBookingChange}
                    displayEmpty
                    disabled={true}
                    renderValue={(selected: number | "") => {
                      if (selected === "") {
                        return (
                          <em>
                            {t("insurance_claim_select_transaction_label")}
                          </em>
                        );
                      }
                      return selectedBooking && selectedBooking.item?.name;
                    }}
                  >
                    {bookings.map((booking, index) => {
                      const { startDate, endDate } = booking;
                      const startDateString = startDate!.toLocaleDateString();
                      const endDateString = endDate!.toLocaleDateString();

                      return (
                        <MenuItem key={index} value={index}>
                          <Box display="block">
                            <Typography variant="body1">
                              {booking.item.name}
                            </Typography>
                            <Typography variant="body2">{`${startDateString} - ${endDateString}`}</Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>

              {/*
                        <Grid container spacing={2} alignItems="center" key={"itemValue"} sx={{pb: 1}}>
                                <Grid item xs={4}>
                                    <Typography>Item Value</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        inputProps={{
                                            style: {
                                                height: "8px",
                                            },
                                        }}
                                        fullWidth
                                        value={itemValue ? "£" + itemValue.toFixed(2) : "..."}
                                        disabled={true}
                                    />
                                </Grid>
                        </Grid> */}

              <Grid
                container
                spacing={2}
                alignItems="center"
                key={"startDate"}
                sx={{ pb: 1 }}
              >
                <Grid item xs={5} md={4}>
                  <Typography>Start Date</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                  <TextField
                    inputProps={{
                      style: {
                        height: "8px",
                      },
                    }}
                    fullWidth
                    value={startDate}
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                key={"endDate"}
                sx={{ pb: 1 }}
              >
                <Grid item xs={5} md={4}>
                  <Typography>End Date</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                  <TextField
                    inputProps={{
                      style: {
                        height: "8px",
                      },
                    }}
                    fullWidth
                    value={endDate}
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Typography
                color={"black"}
                fontWeight={600}
                variant="h6"
                gutterBottom
                component="div"
                style={{ marginTop: 16 }}
              >
                Claim Details
              </Typography>

              <Grid
                container
                spacing={2}
                alignItems="center"
                key={"claimReason"}
              >
                <Grid item xs={5} md={4} sx={{ pb: 1 }}>
                  <Typography>Claim Reason</Typography>
                </Grid>
                <Grid item xs={7} md={8} sx={{ pb: 1 }}>
                  <Select
                    size="small"
                    fullWidth
                    value={chosenClaimReason ? chosenClaimReason.name : ""}
                    onChange={handleClaimReasonChange}
                    displayEmpty
                    renderValue={(selected: string | "") => {
                      if (selected === "") {
                        return (
                          <em>{t("insurance_claim_select_placeholder")}</em>
                        );
                      }
                      return chosenClaimReason && chosenClaimReason.name;
                    }}
                  >
                    {lossTypes.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" key={"claimDate"}>
                <Grid item xs={5} md={4} sx={{ pb: 1 }}>
                  <Typography>Claim Date</Typography>
                </Grid>

                <Grid item xs={7} md={8} sx={{ pb: 1 }}>
                  <TextField
                    inputProps={{
                      style: {
                        height: "8px",
                      },
                    }}
                    disabled={true}
                    multiline={false}
                    rows={1}
                    placeholder={""}
                    fullWidth
                    value={moment(claimDate).format("DD-MM-YYYY")}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                key={"claimValue"}
              >
                <Grid item xs={5} md={4} sx={{ pb: 1 }}>
                  <Typography>Claim Value</Typography>
                </Grid>

                <Grid item xs={7} md={8} sx={{ pb: 1 }}>
                  <TextField
                    inputProps={{
                      style: {
                        height: "8px",
                      },
                    }}
                    fullWidth
                    value={
                      isFocused ? claimValue : formatCurrencyValue(claimValue)
                    }
                    disabled={!selectedBooking}
                    onChange={handleCurrencyChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                  <Collapse
                    isOpen={
                      !!(
                        claimValue &&
                        itemValue &&
                        parseFloat(claimValue) > itemValue
                      )
                    }
                  >
                    <Typography variant={"subtitle2"} color={"#ea554a"}>
                      Claim value is higher than the insured value, please
                      review and submit.
                    </Typography>
                  </Collapse>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={"claimDescription"}
              >
                <Grid item xs={12}></Grid>
                <Grid item xs={12} sx={{ pb: undefined }}>
                  <Typography>Claim Description</Typography>
                </Grid>

                <Collapse isOpen={!!reasonNote} style={undefined}>
                  <Grid item xs={12}>
                    <Typography sx={{ pl: "1rem" }}>{reasonNote}</Typography>
                  </Grid>
                </Collapse>

                <Grid item xs={12} sx={{ pb: 1 }}>
                  <TextField
                    inputProps={{
                      style: {
                        height: undefined,
                      },
                    }}
                    disabled={false}
                    multiline={true}
                    rows={3}
                    placeholder={"Please provide a description of your claim"}
                    fullWidth
                    value={claimDescription}
                    onChange={(e) => {
                      if (e.target.value.length <= 1000) {
                        setClaimDescription(e.target.value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} sx={{ overflow: "hidden" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ marginBottom: matchesSmall ? 1 : 0 }}
                    color={"black"}
                    fontWeight={600}
                  >
                    Supporting Images
                  </Typography>
                  <Typography>
                    {
                      "Please add additional images of any damages or anything that may be useful."
                    }
                  </Typography>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    lg={3}
                    sx={{ overflow: "auto", maxWidth: "100% !important" }}
                  >
                    <br />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      wrap="nowrap"
                      sx={{ ml: "0px !important", pt: "8px" }}
                      style={{
                        overflowX: "auto",
                        width: "max-content",
                        scrollBehavior: "smooth",
                      }}
                      ref={scrollContainerRef}
                    >
                      {uploadedFiles.map((uploadedFile, index) => (
                        <Card
                          key={index}
                          sx={{
                            width: "120px",
                            height: "120px",
                            marginRight: "8px",
                            border: "1.5px solid #dadada",
                            display: "grid",
                            position: "relative", // Solid black dashed border
                            borderRadius: "8px",
                          }}
                        >
                          <CardActionArea
                            sx={{
                              display: "grid",
                              justifyItems: "center",
                            }}
                          >
                            {uploadedFile.name.endsWith(".pdf") ? (
                              <>
                                <AttachFileIcon
                                  sx={{
                                    margin: "auto",
                                    verticalAlign: "middle",
                                    height: "100% !important",
                                    width: "60px",
                                    textAlign: "center",
                                    display: "grid !important",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    margin: "auto",
                                    position: "absolute",
                                    bottom: "0",
                                  }}
                                >
                                  {uploadedFile.name}
                                </Typography>
                              </> // Replace with an icon for PDFs
                            ) : (
                              <img
                                src={uploadedFile.file}
                                alt={`Uploaded ${uploadedFile.name}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </CardActionArea>
                          <Button
                            onClick={() => removeFile(index)}
                            sx={{
                              position: "absolute",
                              right: "-16px",
                              top: "-4px",
                              color: "#6f6d6d",
                            }}
                          >
                            <CancelIcon />
                          </Button>{" "}
                          {/* Position this button as needed */}
                        </Card>
                      ))}
                      <UploadCard onFileSelect={onFileSelect} />
                      <br />
                    </Grid>
                  </Grid>
                  {/*<Typography>*/}
                  {/*    {"Any repair/replace quotes should be emailed to claims@rentmy.com with your booking ID '" + bookingId + "' in the subject line."}*/}
                  {/*</Typography>*/}
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} xs={12} alignItems="center">
                <Grid item xs={11}>
                  <Typography>
                    Please confirm your agreement for your bank details to be
                    passed to Brett Noble Ltd for settlement of your claim
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    sx={{ color: "#ea554a !important", fontSize: "2rem" }}
                    color="success"
                    checked={agreeTerms}
                    onChange={(e) => setAgreeTerms(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                sx={{ paddingTop: "32px", width: "100%", margin: 0 }}
                justifyContent="flex-end"
              >
                <Button
                  onClick={handleClaimSubmit}
                  disabled={
                    !agreeTerms ||
                    !!(
                      claimValue &&
                      itemValue &&
                      parseFloat(claimValue) > itemValue
                    )
                  }
                  sx={{ padding: "16px" }}
                  // disabled={!agreeTerms}
                  className="btn btn-primary full-width"
                >
                  {isLoading ? (
                    <div className="loading-spinner-small"></div>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <MakeClaimModal
        isOpen={open}
        onClose={handleClose}
        onProceedWithClaim={handleClose}
        onGoBack={() => navigate(-1)}
      />
    </>
  );
}
