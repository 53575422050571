import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Input, Card, CardBody, Row, Col, Container } from "reactstrap";
import moment from "moment";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import {
  IConfig,
  MessageClient,
  MessageCreateDto,
  MessageDetailedDto,
  RentMyUserPublicSimpleDto,
  RentMyUserSimpleDto,
  RentMyUserDetailedDto,
  UserClient,
  ImageDto,
  RentMyUserPublicDetailedDto,
} from "../../api/rentMyApi";

import {
  bookingTypeSearchParamsConst,
  getBookingIdFromSubject,
  getConversationTypeFromSubject,
  getItemUrlFromSubject,
  itemTypeSearchParamsConst,
  subjectInUserReadableFormat,
} from "../common/MessageUrlFormatting";
import BackButton from "../common/BackButton";
import ItemSnippet from "../common/ItemSnippet";
import { useUserContext } from "../../context/UserContext";
import { useEnv } from "../../context/env.context";
import AccountPagesSidebar from "./AccountPagesSidebar";
import { LoadingFullScreen } from "../common/LoadingFullScreen";
import KebabMenu from "../booking/Kebab";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

moment.updateLocale("en", {
  calendar: {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    lastWeek: "dddd",
    sameElse: "DD MMM YYYY",
  },
});

// Simplified user type for the sender field
interface SimplifiedUserDto {
  id: string;
  name: string | undefined;
  profileImage: { compressedPath?: string };
}

const convertToSimplifiedUser = (user: RentMyUserDetailedDto): SimplifiedUserDto => {
  return {
    id: user.id,
    name: user.name,
    profileImage: user.profileImage || { compressedPath: "" },
  };
};

export const AccountConversationItem = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user: contextUser, countUnreadMessages } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { subject, otherParticipantId } = useParams();

  const [clientInitialised, setClientInitialised] = useState<boolean>(false);
  const [userClient, setUserClient] = useState(
    new UserClient(new IConfig("notoken"), apiServerUrl)
  );
  const [messageClient, setMessageClient] = useState<MessageClient>(
    new MessageClient(new IConfig("notoken"), apiServerUrl)
  );
  const [conversationMessages, setConversationMessages] = useState<
    MessageDetailedDto[]
  >([]);
  const [otherParticipant, setOtherParticipant] =
    useState<RentMyUserPublicDetailedDto>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [sendingMessage, setSendingMessage] = useState<string>("");
  const [isSendingMessageInvalid, setIsSendingMessageInvalid] = useState<
    boolean | null
  >(null);
  const [user, setUser] = useState<RentMyUserDetailedDto | null>(null);

  let mostRecentDateInReverseOrder: string | undefined = undefined;
  const messageMaxLength = 200;

  const kebabMenuItems = [
    {
      label:
        getConversationTypeFromSubject(subject!) == bookingTypeSearchParamsConst
          ? t("account_conversations_to_booking")
          : t("account_conversations_to_item"),
      onClick:
        getConversationTypeFromSubject(subject!) == bookingTypeSearchParamsConst
          ? () => navigate(getBookingIdFromSubject(subject!))
          : () => navigate(getItemUrlFromSubject(subject!)),
      icon: faInfoCircle,
      notification: false,
      visible: true,
    },
  ];

  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    async function initClient() {
      try {
        const token = await getAccessTokenSilently();
        const newUserClient = new UserClient(new IConfig(token), apiServerUrl);
        const newUser = await newUserClient.current();
        setUser(newUser);  // Ensure user is set properly here
        setUserClient(newUserClient);
        setMessageClient(new MessageClient(new IConfig(token), apiServerUrl));
        setClientInitialised(true);
      } catch (error) {
        console.error("Error initializing client:", error);
        toast.error("Failed to initialize user client. Please log in again.");
        navigate("/login");  // Redirect to login if there's an error
      }
    }
    initClient();
  }, [getAccessTokenSilently, navigate]);

  useEffect(() => {
    if (clientInitialised) {
      getConversationMessages();
    }
  }, [clientInitialised]);

  useEffect(() => {
    scrollToBottom();
  }, [dataLoaded, conversationMessages]);

  async function getConversationMessages() {
    let localUserId = user?.id;

    try {
      if (!localUserId) {
        const token = await getAccessTokenSilently();
        const localUserClient = new UserClient(new IConfig(token), apiServerUrl);
        const fetchedUser = await localUserClient.current();
        setUser(fetchedUser);
        localUserId = fetchedUser.id;
      }

      await messageClient
        .detailed33(
          localUserId,
          otherParticipantId,
          subject,
          200,
          1,
          false,
          false
        )
        .then((res) => {
          if (res.dataCount > 0) {
            const promises: any[] = [];
            if (res.data) {
              for (const message of res.data!) {
                if (message && !message.dateRead && message.sender?.id !== localUserId)
                  promises.push(messageClient.read(message.id));
              }
            }

            const sortedMessages = res?.data?.sort((a, b) =>
              moment(a.dateSent).isBefore(moment(b.dateSent))
                ? 1
                : moment(a.dateSent).isAfter(moment(b.dateSent))
                ? -1
                : 0
            );

            setConversationMessages(sortedMessages ?? []);
            userClient.detailed52(otherParticipantId!).then((otherUser) => {
              setOtherParticipant(otherUser);
            });
            setDataLoaded(true);
            Promise.all(promises).then(() => {
              countUnreadMessages();
            });
          } else {
            setDataLoaded(true);
          }
        });
    } catch (error) {
      console.error("Error fetching conversation messages:", error);
      toast.warn("No previous messages found for this user");
    }
  }

  const scrollToBottom = () => {
    const elementControllingMessageBodyScroll =
      document.getElementById("message-card");

    if (!elementControllingMessageBodyScroll) {
      return;
    }

    elementControllingMessageBodyScroll.scrollTo(
      0,
      elementControllingMessageBodyScroll.scrollHeight
    );
  };

  const isSendingMessageInvalidFunction = (messageValue: string) => {
    return messageValue.length <= 0;
  };

  const displayFirstDateInConversation = (firstDate: Date) => {
    return (
      <span className="message-date d-flex justify-content-center">
        {moment(firstDate).calendar()}
      </span>
    );
  };

  // DEVNOTE - logic is more complex as the messages are mapped in reverse order
  const displayDateIfMessageOnDifferentDay = (messageDate: Date) => {
    const messageDateInStringFormat = moment(messageDate).calendar();

    if (
      mostRecentDateInReverseOrder === undefined ||
      mostRecentDateInReverseOrder === messageDateInStringFormat
    ) {
      mostRecentDateInReverseOrder = messageDateInStringFormat;
      return <div className="message-date-spacing"></div>;
    } else {
      const dateDividerElement: JSX.Element = (
        <span className="message-date d-flex justify-content-center">
          {mostRecentDateInReverseOrder}
        </span>
      );

      mostRecentDateInReverseOrder = messageDateInStringFormat;
      return dateDividerElement;
    }
  };

  const onNewMessageSending = async () => {
    const removeAllWhitespaceRegex: RegExp = /\s/g;
    if (sendingMessage.replaceAll(removeAllWhitespaceRegex, "") === "") {
      setIsSendingMessageInvalid(true);
      return;
    }
  
    const createMessageBody: MessageCreateDto = new MessageCreateDto({
      active: true,
      content: sendingMessage,
      recipient: otherParticipantId!,
      subject: subject,
    });
  
    try {
      const messageCreateResponse = await messageClient.createPOST16(createMessageBody);
      const simplifiedUser: SimplifiedUserDto = {
        id: user!.id,
        name: user!.name,
        profileImage: user!.profileImage || { compressedPath: "" },
      };
      const newMessage: MessageDetailedDto = {
        ...messageCreateResponse,
        content: sendingMessage,  // Ensure content is included
        sender: simplifiedUser as any,
        dateSent: new Date(), // Ensure dateSent is set to the current date
        init: function (_data?: any): void {
          throw new Error("Function not implemented.");
        },
        toJSON: function (data?: any) {
          throw new Error("Function not implemented.");
        }
      };
      const clone: MessageDetailedDto[] = cloneDeep(conversationMessages);
      clone.push(newMessage);
      const sortedMessages = clone.sort((a, b) =>
        moment(a.dateSent).isBefore(moment(b.dateSent)) ? 1 : moment(a.dateSent).isAfter(moment(b.dateSent)) ? -1 : 0
      );
      setConversationMessages(sortedMessages);
      setSendingMessage("");
      scrollToBottom(); // Ensure the new message is visible
    } catch (error) {
      toast.error(t("account_conversations_message_sent_error"));
    }
  };

  return (
    <Container className="mt-4 account-messages account-settings-container">
      <Row>
        <Col className="hide-on-mobile hide-on-tablet" md={4} lg={4} xl={3}>
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9} className="auto-margin">
          <Card>
            {!dataLoaded && <LoadingFullScreen />}
            <CardBody id="message-card">
              {dataLoaded && (
                <>
                  <div className={`d-flex p-2 align-items-center justify-content-between message-title-container`}>
                    <div className="d-flex align-items-center overflow-hidden">
                      {!isMobile && (
                        <BackButton onClick={() => navigate("/account/messages")} />
                      )}
                      <div>
                        <h3 className="mb-0">{otherParticipant?.name}</h3>
                        <p className="mb-0 small">
                          {subjectInUserReadableFormat(subject!)}
                        </p>
                      </div>
                    </div>
                    <div className="kebab-container">
                      <KebabMenu
                        menuItems={kebabMenuItems}
                        id={subject ?? "message-subject"}
                      />
                    </div>
                  </div>
                  <div className="message-body-container">
                    {conversationMessages.length === 0 && <div>No messages yet.</div>}
                    {conversationMessages.length > 0 && conversationMessages.map((message, index) => {
                      const isSender = message.sender?.id === user?.id;
                      const profilePic = message.sender?.profileImage?.compressedPath || "assets/img/profile-pic-placeholder.webp";
                      return (
                        <div
                          key={`conversations_messages_${index}`}
                          className={`message-body${isSender ? " own-message" : ""}`}
                        >
                          {index === conversationMessages.length - 1 && displayFirstDateInConversation(message.dateSent)}

                          <ItemSnippet
                            wantInCard={false}
                            icon={{
                              userSetup: {
                                userProfilePicSrc: profilePic,
                                userId: message.sender?.id,
                                doesTitleRedirectOnClick: true,
                              },
                            }}
                            text={{
                              title: "",
                              text: message.content ?? "",
                              secondaryText: moment(message.dateSent).format("h:mm"),
                            }}
                          />

                          {displayDateIfMessageOnDifferentDay(message.dateSent)}
                        </div>
                      );
                    })}
                  </div>
                  <div className="message-actions d-flex justify-content-between align-items-center">
                    <Input
                      invalid={isSendingMessageInvalid ?? false}
                      id="item-listing-title"
                      type="textarea"
                      value={sendingMessage}
                      onChange={(e) => {
                        setSendingMessage(e.target.value);
                        setIsSendingMessageInvalid(isSendingMessageInvalidFunction(e.target.value));
                      }}
                      onKeyDown={async (e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          await onNewMessageSending();
                        }
                      }}
                      maxLength={messageMaxLength}
                      placeholder={t("account_conversations_send_message_placeholder")}
                      required
                      className="input-field"
                      style={{ maxHeight: "44px", minHeight: "44px" }}
                    />
                    <Button color="primary" onClick={onNewMessageSending}>
                      {t("send")}
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};