import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import {
  IConfig,
  ItemClient,
  ItemCreateDto,
  TermsClient,
  TermsSimpleDto,
  TermsSimpleDtoPaginationDto,
} from "../../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { LoadingFullScreen } from "../common/LoadingFullScreen";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Range } from "react-date-range";
import {
  idConst,
  termsOfServiceName,
  termsOfServiceType,
  toBookingConfirmationUrl,
} from "./TermsRedirect";
import { userLoginAnalytics, userSignupAnalytics } from "../../api/analytics";
import { useUserContext } from "../../context/UserContext";

export function Terms({
  itemListingPayload,
  itemBookingPayload,
  isLogin,
  withAcceptButton,
}: {
  itemListingPayload: ItemCreateDto | undefined;
  itemBookingPayload: Range | undefined;
  isLogin: boolean | undefined;
  withAcceptButton: boolean | undefined;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently, logout, isAuthenticated, isLoading } =
    useAuth0();
  const navigate = useNavigate();
  const { user, hasAcceptedTerms, hasSetTermsStatus, updateAcceptedTerms } =
    useUserContext();

  const [termsClient, setTermsClient] = useState<TermsClient>();
  // const [acceptedTerms, setAcceptedTerms] = useState<AcceptedTermsDto | undefined>(undefined)
  const [itemClient, setItemClient] = useState<ItemClient>();
  const [areClientsInitialisedWithAuth, setAreClientsInitialisedWithAuth] =
    useState<boolean>();
  const [termsDTO, setTermsDTO] = useState<TermsSimpleDto>();

  const searchParams = new URLSearchParams(window.location.search);

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      // console.log("Returning here...")
      // console.log({isLoading, isAuthenticated})
      return;
    }

    async function initClient() {
      // console.log("!isAuthenticated")
      // console.log(!isAuthenticated)
      const token = await getAccessTokenSilently();
      const termsClient = new TermsClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );
      const itemClient = new ItemClient(
        new IConfig(token),
        process.env.REACT_APP_API_ENDPOINT
      );

      setTermsClient(termsClient);
      setItemClient(itemClient);
      setAreClientsInitialisedWithAuth(true);
    }

    initClient();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    // console.log("!areClientsInitialisedWithAuth")
    // console.log(!areClientsInitialisedWithAuth)
    if (!areClientsInitialisedWithAuth) {
      return;
    }

    if (hasAcceptedTerms && withAcceptButton) redirectBackWithContext();

    termsClient!
      .accepted2(termsOfServiceType)
      .then((x) => {
        // console.log("TERMS RESPONSE")
        // console.log(x)

        if (isLogin && withAcceptButton && x && x.terms && x.terms.active) {
          updateAcceptedTerms(true);
          console.log("user login event!");
          userLoginAnalytics({});
          // redirectBackWithContext()
          // window.location.href = window.location.origin
        }
      }).catch((x) => {
        console.log("TERMS RESPONSE")
        console.log(x)
    })
      .finally(() => {
        populateTermsPage();
      });
  }, [areClientsInitialisedWithAuth]);

  function populateTermsPage() {
    termsClient!
      .terms2()
      .then((result: TermsSimpleDtoPaginationDto) => {
        if (!result.data) {
          toast.error("no response on terms call, please contact an admin");
          return;
        }

        const termsOfServiceTermDto = result.data.find(
          (x) => x.name === termsOfServiceName
        );

        if (termsOfServiceTermDto === undefined) {
          toast.error(
            "Could not find a term with the name" +
              termsOfServiceName +
              ". Please contact admin"
          );
          return;
        }

        setTermsDTO(termsOfServiceTermDto);
        setHasLoaded(true);
      })
      .catch((error) => {
        toast.error(
          "Could not load the terms and conditions, please contact an admin"
        );
      });
  }

  function onDenyClick() {
    logout({ returnTo: window.location.origin });
  }

  const redirectBackWithContext = () => {
    if (itemListingPayload !== undefined) {
      listAnItem();
    } else if (itemBookingPayload !== undefined) {
      forwardToBookingConfirmation();
    } else if (isLogin) {
      // console.log("Forwarding to homepage")
      navigate("/");
      // forwardToProfile()
    } else {
      // console.error("Don't know what we are accepting.")
    }
  };

  async function onAcceptClickAsync() {
    const termId = termsDTO!.id;
    // console.log("termId", termId)
    // DEVNOTE - dont need to termsClient.issue(x) as this is a term that is auto applied to the user by backend. I have made this assumption as all users have this terms of service present.

    if (!hasAcceptedTerms) userSignupAnalytics({});

    termsClient?.accept(termId).then(() => {
      try {
        updateAcceptedTerms(true);
        redirectBackWithContext();
      } catch (e) {
        // console.log(e)
      }
    });
  }

  function forwardToBookingConfirmation() {
    if (
      itemBookingPayload &&
      itemBookingPayload.startDate &&
      itemBookingPayload.endDate
    ) {
      window.location.href = toBookingConfirmationUrl(
        `?${idConst}=` + searchParams.get(idConst)!,
        itemBookingPayload.startDate,
        itemBookingPayload.endDate
      );
    }
  }

  function forwardToProfile() {
    navigate("/account/profile");
  }

  function listAnItem() {
    itemClient!
      .createPOST12(undefined, itemListingPayload!)
      .then((response) => {
        toast.success(
          itemListingPayload!.isPublished
            ? t("item_listing_created_successfully_publish")
            : t("item_listing_created_successfully_save")
        );

        navigate("/account/my-items");
      })
      .catch((error: any) => {
        if (itemListingPayload!.isPublished) {
          toast.error(t("publish_error"));
        } else {
          toast.error(t("save_error"));
        }
      });
  }

  return (
    <>
      {!hasLoaded && <LoadingFullScreen />}

      {hasLoaded && (
        <>
          {withAcceptButton && (
            <>
              <div
                className={
                  "fixed-bottom-accept-banner" + (isLogin ? " mt-3" : "")
                }
              >
                <h2 className="mr-4">{t("terms_accept_label")}</h2>
                <div className="button-section">
                  <Button color="secondary" onClick={onDenyClick}>
                    {t("terms_deny")}
                  </Button>
                  <Button color="primary" onClick={onAcceptClickAsync}>
                    {t("terms_accept")}
                  </Button>
                </div>
              </div>
              <div className="fixed-bottom-accept-banner-spacing-standin"></div>
            </>
          )}
          {termsDTO && (
            <div
              className="bottom-padding-50"
              dangerouslySetInnerHTML={{ __html: termsDTO.content! }}
            />
          )}
        </>
      )}
    </>
  );
}
