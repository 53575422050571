import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import {
  DailyPriceSection,
  DiscountsSection,
  ItemAvailabilitySection,
  ItemValueInput,
  ReceiptToggle,
  RentalInsuranceSection,
  SelectPurchaseDate,
  SetOriginalValue,
} from "./ItemCostAndValueForm";
import { useEnv } from "../../context/env.context";

import { InsurancePlanDto, PricePeriod } from "../../api/rentMyApi";
import { PricePeriodError } from "../../models/interfaces/PricePeriodEnhanced";

interface ItemCostAndValueProps {
    weeklyDiscount: Number | undefined ;
    setWeeklyDiscount: React.Dispatch<React.SetStateAction<Number | undefined>>;

    monthlyDiscount: Number | undefined;
    setMonthlyDiscount: React.Dispatch<React.SetStateAction<Number | undefined>>;
  updateErrors: any;
  itemValueProperties: any;
  setItemValueProperties: Dispatch<SetStateAction<any>>;
  fieldsValidation: any;
  setPricePeriod: Dispatch<SetStateAction<PricePeriod>>;
  pricePeriod: PricePeriod;
  pricePeriodFormValidation: PricePeriodError[];
  updatePricePeriodErrors: any;
  isEditing: boolean;
  hasDeposit: boolean;
  setHasDeposit: Dispatch<SetStateAction<boolean>>;
  ChosenInsuranceId?: string;
  setChosenInsuranceId?: Dispatch<SetStateAction<string | undefined>>;
  insurancePlans?: InsurancePlanDto[] | null;
  disabled: boolean;
  insuranceRequired: boolean;
  setInsuranceRequired: Dispatch<SetStateAction<boolean>>;
}

export default function ItemCostAndValue({
    weeklyDiscount,
    setWeeklyDiscount,
    monthlyDiscount,
  setMonthlyDiscount,
  updateErrors,
  itemValueProperties,
  setItemValueProperties,
  fieldsValidation,
  setPricePeriod,
  pricePeriod,
  pricePeriodFormValidation,
  updatePricePeriodErrors,
  isEditing,
  hasDeposit,
  setHasDeposit,
  ChosenInsuranceId,
  setChosenInsuranceId,
  insurancePlans = null,
  disabled,
  insuranceRequired,
  setInsuranceRequired,
}: ItemCostAndValueProps) {
  const { t } = useTranslation();

  const [tempPriceView, setTempPriceView] = useState<string | undefined>(
    undefined
    );

    const [tempMonthlyPriceView, setTempMonthlyPriceView] = useState<string | undefined>(
        undefined
    );

    const [tempWeeklyPriceView, setTempWeeklyPriceView] = useState<string | undefined>(
        undefined
    );

  const [existingPriceBool, setExistingPriceBool] = useState<boolean>(false);
  const [minimumBookingDays, setMinimumBookingDays] = useState<
    number | undefined
  >(undefined);
  const [maximumBookingDays, setMaximumBookingDays] = useState<
    number | undefined
  >(undefined);
  const [showInsuranceToggle, setShowInsuranceToggle] = useState(false);

  const [insuranceImage, setInsuranceImage] = useState<string | undefined>(
    undefined
  );
  const [insuranceName, setInsuranceName] = useState<string | undefined>(
    undefined
  );
  const [insurancePlanID, setInsurancePlanID] = useState<string | undefined>(
    undefined
  );
  const [insurancePlanLowerLimit, setInsurancePlanLowerLimit] = useState<
    number | undefined
  >(undefined);
  const [insurancePlanUpperLimit, setInsurancePlanUpperLimit] = useState<
    number | undefined
  >(undefined);
  const { apiServerUrl } = useEnv();

  const enableInsurance =
    process.env.REACT_APP_SHOW_INSURANCE &&
    /true/.test(process.env.REACT_APP_SHOW_INSURANCE);

  useEffect(() => {
    if (
      !existingPriceBool &&
      isEditing &&
      pricePeriod &&
      pricePeriod.price &&
      pricePeriod.price.daily
    ) {
      setTempPriceView(pricePeriod.price.daily.toString() ?? undefined);
      setMaximumBookingDays(pricePeriod.maximumBookingDays ?? undefined);
      setMinimumBookingDays(pricePeriod.minimumBookingDays ?? undefined);
      setHasDeposit(
        !!(
          pricePeriod &&
          pricePeriod.price &&
          pricePeriod.price.deposit &&
          Number(pricePeriod.price.deposit) > 0
        )
      );
      setExistingPriceBool(true);
    }
  }, [pricePeriod]);

  useEffect(() => {
    if (
      enableInsurance &&
      insurancePlans &&
      insurancePlans[0] &&
      insurancePlans[0].insuranceCompany
    ) {
      // Save the image, title and upper and lower limits in state variables
      const insuranceCompany = insurancePlans[0].insuranceCompany;
      const image = insurancePlans[0].insuranceCompany.image;
      const id = insurancePlans[0].id;
      const insuranceLowerLimit = insurancePlans[0].insurancePlanLowerLimit;
      const insuranceUpperLimit = insurancePlans[0].insurancePlanUpperLimit;
      setInsurancePlanLowerLimit(insuranceLowerLimit);
      setInsurancePlanUpperLimit(insuranceUpperLimit);
      setInsurancePlanID(id);
      if (insuranceCompany) {
        const src = image?.compressedPath
          ? image.compressedPath
          : "/assets/img/placeholder.jpg";
        setInsuranceImage(src);
        setInsuranceName(insurancePlans[0].name || undefined);
      }

      // Insurance toggle should only be shown if item value is between lower and upper limits or if limits do not exist
      // When insurance plan options change - e.g because of a change in category - check again if the conditions are met
      if (
        itemValueProperties.itemValue &&
        (!insurancePlanLowerLimit ||
          (insurancePlanLowerLimit &&
            itemValueProperties.itemValue > insurancePlanLowerLimit)) &&
        (!insurancePlanUpperLimit ||
          (insurancePlanUpperLimit &&
            itemValueProperties.itemValue < insurancePlanUpperLimit))
      ) {
        setShowInsuranceToggle(true);
      } else {
        setShowInsuranceToggle(false);
      }
    } else {
      // If insurance plan options change - e.g because of a change in category - and item is not eligible for insurance, hide insurance toggle
      setShowInsuranceToggle(false);
    }
  }, [insurancePlans]);

  const updateItemValueProperties = (newValue: any, field: string) => {
    const clone = cloneDeep(itemValueProperties);
    clone[field] = newValue;
    setItemValueProperties(clone);
    if (field !== "itemValue") {
      return;
    } else {
      // Insurance toggle should only be shown if item value is between lower and upper limits or if limits do not exist
      if (
        insurancePlans?.length &&
        (!insurancePlanLowerLimit ||
          (insurancePlanLowerLimit &&
            (newValue > insurancePlanLowerLimit || clone.insuranceRequired))) &&
        (!insurancePlanUpperLimit ||
          (insurancePlanUpperLimit &&
            (newValue < insurancePlanUpperLimit || clone.insuranceRequired)))
      ) {
        setShowInsuranceToggle(true);
      } else {
        setShowInsuranceToggle(false);
        setInsuranceRequired(false);
      }
    }
  };

  return (
    <>
      <Card className="price-period-section item-listing-card">
        <CardBody>
          <div id="standard-price-period-section">
            <h2>{t("item_listing_cost_and_value_title")}</h2>
            <Row>
              {disabled && (
                <div className="invalid-feedback d-block ml-2 mb-2">
                  Please select a category first
                </div>
              )}
            </Row>
          </div>
          <>
            <div id="item-cost-and-value-section">
              <Row>
                <SelectPurchaseDate
                  t={t}
                  itemPurchaseDate={itemValueProperties.purchaseDate}
                  disabled={disabled}
                  setItemPurchaseDate={(newValue: any) =>
                    updateItemValueProperties(newValue, "purchaseDate")
                  }
                />
                <SetOriginalValue
                  t={t}
                  disabled={disabled}
                  updateErrors={updateErrors}
                  setOriginalValue={(newValue: number) =>
                    updateItemValueProperties(newValue, "originalValue")
                  }
                  originalValue={itemValueProperties.originalValue}
                  originalValueValidation={fieldsValidation.originalValue}
                />
                <ItemValueInput
                  t={t}
                  updateErrors={updateErrors}
                  disabled={disabled}
                  setItemValue={(newValue: any) =>
                    updateItemValueProperties(newValue, "itemValue")
                  }
                  itemValue={itemValueProperties.itemValue}
                  itemValueValidation={fieldsValidation.itemValue}
                />
                {isEditing && (
                  <ReceiptToggle
                    t={t}
                    setReceiptToggle={(newValue: any) =>
                      updateItemValueProperties(newValue, "receipt")
                    }
                    receipt={itemValueProperties.receipt}
                  />
                )}
              </Row>
            </div>
          </>
        </CardBody>
      </Card>

      {enableInsurance && insurancePlans && insurancePlans[0] && (
        <RentalInsuranceSection
          t={t}
          isInsuraceToggleOpen={
            itemValueProperties.itemValue && showInsuranceToggle
          }
          insuranceImage={insuranceImage}
          toggleSetup={{
            value: insuranceRequired,
            setValue: setInsuranceRequired,
            disabled: false,
          }}
          setChosenInsuranceId={setChosenInsuranceId || (() => {})}
          insuranceId={insurancePlanID}
        />
      )}

      <Card className="price-period-section item-listing-card">
        <CardBody>
          <div id="standard-price-period-section">
            <h2>{t("daily_price_title")}</h2>
          </div>
          <>
            <div id="item-cost-and-value-section">
              <Row>
                <DailyPriceSection
                                  t={t}
                                  tempPriceView={tempPriceView}
                                  setPricePeriod={setPricePeriod}
                                  pricePeriod={pricePeriod}
                                  setTempPriceView={setTempPriceView}
                                  pricePeriodFormValidation={pricePeriodFormValidation}
                                  updatePricePeriodErrors={updatePricePeriodErrors}
                                  weeklyDiscount={weeklyDiscount}
                                  setWeeklyDiscount={setWeeklyDiscount}
                                  monthlyDiscount={monthlyDiscount}
                                  setMonthlyDiscount={setMonthlyDiscount}
                />
              </Row>
            </div>
                  </>
        </CardBody>
          </Card>

          {/* 
          <Card className="price-period-section item-listing-card">
              <CardBody>
                  <div id="standard-price-period-section">
                       <h2>{"Set your discounts"}</h2>
                  </div>

                  <>
                      <div id="discount-cost-and-value-section">
                          <Row>
                              
                              <DiscountsSection
                                  t={t}
                                  tempMonthlyPriceView={tempMonthlyPriceView}
                                  tempWeeklyPriceView={tempWeeklyPriceView}
                                  pricePeriodFormValidation={pricePeriodFormValidation}
                                  updatePricePeriodErrors={updatePricePeriodErrors}
                                  weeklyDiscount={weeklyDiscount}
                                  setWeeklyDiscount={setWeeklyDiscount}
                                  monthlyDiscount={monthlyDiscount}
                                  setMonthlyDiscount={setMonthlyDiscount}

                                  pricePeriod={pricePeriod}
                                  setPricePeriod={setPricePeriod}
                              />
                          </Row>
                      </div>
                  </>
              </CardBody>
          </Card>*/}

      <Card className="price-period-section item-listing-card">
        <CardBody>
          <div id="standard-price-period-section">
            <h2>{t("item_listing_booking_duration")}</h2>
          </div>
          <>
            <div id="item-cost-and-value-section">
              <Row>
                <ItemAvailabilitySection
                  tempMaxDays={maximumBookingDays}
                  setTempMaxDays={setMaximumBookingDays}
                  tempMinDays={minimumBookingDays}
                  setTempMinDays={setMinimumBookingDays}
                  pricePeriod={pricePeriod}
                  setPricePeriod={setPricePeriod}
                  pricePeriodFormValidation={pricePeriodFormValidation}
                  updatePricePeriodErrors={updatePricePeriodErrors}
                />
              </Row>
            </div>
          </>
        </CardBody>
      </Card>
    </>
  );
}
